.App {
  background-color: #23262b;
}

/* header section starts  */

.header{
  height : 60px;
  background-color: rgba(12, 16, 23,0.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px  50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
}
.left img{
  width: 7rem;
  cursor: pointer;
}
.right ul {
    list-style: none;
    display: flex;
    color: white;
    font-weight: 900;
    
}
.right li{
    margin:  10px;
    padding: 10px;
    cursor: pointer;
    transition: 0.3s ;
}

.right li .active{
   border-bottom: 4px solid yellowgreen;
}
.right li:hover{
  color: wheat;
  
}
.burger-right{
  display: none;
  color: white;
  background-color: #23262b;
  width: 70vw;
  height: 100vh;
  position: fixed;
  top: -120vh;
  right: 0;
  transition: 0.3s ease-in-out;
}
.true-burger-right{
  top: 0;
}
.hamicon-cross{
  position: absolute;
  top: 30px;
  right: 50px;
}
.burger-right ul{
  margin-top: 60px;
  list-style: none;
  flex-direction: column;
}
.hamburger{
  display: none;
}
.hamicons{
  color: white;
  
}


/* hamburger menu  */
 @media screen and (max-width : 785px) {
       .right{
        display: none;
       }
        .burger-right{
          display: flex;
        
        }
        .hamburger{
          display: block;
        }
 }


/* header section ends here  */
/* home section start here  */


.hero{
  background: url('./assets/Avilekh-bg.jpg')  center center /cover no-repeat fixed;
  background-blend-mode:  screen;
}

.text-field{
  padding: calc(20vh + 5px) calc(20vw + 5px);
  color: white;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.intro{
   font-size: 5rem;
   padding-left: 10px;
   border-left: 4px solid yellowgreen;
}
.intro span{
  color: yellowgreen;
  font-weight: bolder;
}
.text{
  margin: 10px 0;
  font-family: 'Courier New', Courier, monospace;
}


.button{
  margin: 30px 0;
}
.button a{
  color: white;
  text-decoration: none;
  font-family: 'Courier New', Courier, monospace;
  font-size: 20px;
  margin-right: 10px;
  border: 2px solid yellowgreen;
  padding: 10px;
  transition: 0.5s ease-in-out;
}
.button a:hover{
  border: 2px solid white;
  
  color: wheat;
  
}

@media screen and (max-width : 845px) {
  .left img{
    width: 6rem;
  }
  .intro{
    font-size: 3rem;
  }
  .text-field{
    padding: 20vh 10vw;
  }
  .text{
    margin:  20px 0; 
  }
  .button a{
    font-size: 16px;
  }
 
}

/* home section ends here */
/* skills section starts  here */

.skills{
  min-height: 100vh;
  color: white;
  padding: 10vh 20vw;

}
.heading{
  border-bottom: 4px solid yellowgreen;
  width: max-content;
  font-size: 3rem;
}
.tech{
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.techG{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 20px auto;
    padding: 10px;
    /* border: 2px solid #0d1118; */
    border :  3px solid rgba(249,249,249,0.1);
    box-shadow : rgb(0 0 0 / 69%) 0px 26px 30px -10px,
               rgb(0 0 0 /73%) 0px 16px 10px -10px;
    border-radius: 10px;
    transition: 0.3s ease-in;
    cursor: pointer;
}
.techG img{
  width: 40%;
}
.techG:hover{
  transform: scale(1.1);
}

@media screen and (max-width : 914px) {
  .skills{
    padding: 5vh 5vw;
    min-height: auto;
  }
 
  .heading{
    font-size: 1.5rem;
    width: fit-content;
  }
  .techG {
    width : 40%;
    margin: 10px auto;
    font-size: 12px;
  }
 .skills .text{
  font-size: 12px;
 }

}

/* skills section ends here  */
/* projects section starts here  */

.projects{
  min-height: 100vh;
  color: white;
  padding: 10vh 20vw;
}
.projects-box{
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
.projects-card{
  margin: 20px auto;
  position: relative;
  width: fit-content;
  padding: 20px;
  border :  3px solid rgba(249,249,249,0.1);
    box-shadow : rgb(0 0 0 / 69%) 0px 26px 30px -10px,
               rgb(0 0 0 /73%) 0px 16px 10px -10px;
    border-radius: 10px;
    transition: 0.3s ease-in;
   
}
.projects-card:hover{
  border:3px solid rgb(169, 164, 164);
}
 .preview{
  color: white;
  text-decoration: none;
  position: absolute;
  right: 20px;
  bottom: 40px;
  font-weight: 200;
  font-size: 16px;
  padding: 5px ;
  border: 2px solid rgb(181, 175, 175);
  cursor: pointer;
  border-radius: 8px;
}

.gitIcon{
 position: absolute;
 top: 10px;
 right: 15px;
 color: white;
 background-color: black;
 padding: 5px;
 border-radius: 10px;
 border: 2px solid white;
 font-size: 1.5rem;
 transition: 0.3s ease-out;
}
.projects-card a:hover{
  border: 2px solid yellowgreen;
  transform: scale(1.1);
}
.projects-card img{
  border: 2px solid gray;
  width: 250px;
  height: 250px;
  border-radius: 12px;
  object-fit: cover;
}
.title{
  margin-top: 20px;
  font-family: cursive;
  font-size: 1.2rem;
}
pre{
  color: wheat;
}

@media screen and (max-width : 914px) {
  .projects{
    padding: 5vh 5vw;
  }
  .heading{
    font-size: 1.5rem;
    width: fit-content;
  }
  
  .projects-card{
    width: 250px;
  }
}

/* projects section ends here  */
/* contact section starts here  */
 
.contactus{
  min-height: 100vh;
  color: white;
  padding: 5vh 20vw;
}
.heading{
  font-size: 2rem;
}

.formdiv{
  margin-top: 30px;
 
}
.inputdiv{
  margin: 10px 0;
  margin-right: 30px;
}
.input{
  padding: 10px;
  width: 300px;
  border: none;
  outline: none;
  background-color: #23262b;
  color: white;
  font-size: 1.2rem;
  border-bottom: 2px solid wheat;
}
.form{
  display: flex;
}
textarea{
  resize: none;
  border-bottom: none;
  border: 2px solid wheat;
  background-color: #23262b;
  color: white;
  padding: 10px;
  font-size: 1.2rem;
  width: 650px;
  height: 150px;
  outline: none;
}
.submit{
  padding: 10px 20px;
  background-color: #23262b;
  border: 2px solid wheat;
  color: white;
  cursor: pointer;
  font-size: 1.2rem;
}
.submit:hover{
 border:2px solid yellowgreen;
}
@media screen and (max-width : 993px) {
     .contactus{
      padding: 5vh 5vw;
     }
     .form{
      display: flex;
      flex-direction: column;
      align-items: center;
     }
     .formdiv{
      display: flex;
      flex-direction: column;
      align-items: center;
     }
     .input{
      width: 80vw;
     }
     textarea{
      width: 80vw;
      height: 100px;
     }
     .submit{
      width: 84vw;
      font-size: 1rem;
     }
     .inputdiv{
      margin-right: 0;
     }
}



/* contact section ends here  */